<template>
  <AppLayout>
    <ViewWrapper
      title="folders"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :tab="tab"
    >
      <div id="repository-sign-reports">
        <Portal to="action-bar">
          <keep-alive>
            <ItemActions
              :id="repositoryId"
              item="repositories-sign-report"
              :columns="[]"
              group-by=""
              order=""
              :filter-by="[]"
              criteria=""
              active-view=""
              :hide-actions="[
                'toggle',
                'sort',
                'group',
                'filter',
                'activeView',
              ]"
              :simple-search-filter="true"
              :refresh="false"
              :export="false"
              :export-data="exportDataList"
              @refresh="refreshData"
              @search="searchRow"
              @export="export_Data"
              @filter="getData"
            />
          </keep-alive>
        </Portal>

        <SimpleFilter
          :id.sync="repositoryId"
          :workspace-id.sync="workspaceId"
          :columns="_columns"
          :filter-by.sync="filterBy"
          :workspace="true"
          name="Select Folder"
          :workspace-list="workspaceList"
          :list="repositoriesList"
          module="repository"
          :module-id="repositoryId"
          :active-view="activeView"
          :fid.sync="filterId"
          :filter-list="WfilterList"
          :w-id="workspaceId"
          @update="getSignReport"
          @saveData="saveSheet"
          @viewFilter="showFilterList = true"
        />

        <SaveSheet
          v-model="issavesheet"
          :filter="filterBy"
          :repository-id="repositoryId"
          :page="'repositorySignReport'"
          :edit="editData"
          @success="success = true"
        />

        <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />

        <div class="content">
          <div v-if="showFilterList && filterId === '-1'" class="col-12">
            <div>
              <Breadcrumbs :breadcrumbs="breadcrumb" @reset="homeRepository" />
            </div>
          </div>
          <FilterListType
            v-if="showFilterList && filterId === '-1'"
            :mode="mode"
            :items="savedItems"
            :columns="columnss"
            :filter-list="savedList"
            @view="openFilter"
            @isDelete="deleteItem"
            @isSavesheet="edit"
          />
          <template v-else-if="repositorySignReportList.length">
            <SignReportList
              :mode="mode"
              :items="items"
              :columns="columns"
              @view="openFile"
              @history="showHistory"
            />
          </template>

          <template v-else-if="!loadingBarContent">
            <StateWrapper
              icon="eva-archive-outline"
              title="Generate Report"
              description="Select workspace and repository to display data"
              style="margin-top: 20px"
            />
          </template>
        </div>

        <Pagination
          v-if="repositorySignReportList.length || filterId === '-1'"
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          :file-sheet="hideFileSheet"
        />

        <FileSheet
          v-model="isFileSheetVisible"
          :file="selectedFile"
          :repository-field="[]"
          :options="options"
        />
        <ProcessHistory
          v-if="historyProcessId"
          :process-id="historyProcessId"
          :selected-process="selectedProcess"
          @close="historyProcessId = 0"
        />
        <ConfirmDelete
          v-model="isDeleteConfirmationVisible"
          :delete-data="deleteData"
          @delete="success = true"
        />
      </div>
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import { Portal } from "portal-vue";
import {
  repository,
  user,
  report,
  workspace,
  dashboard,
} from "@/api/factory.js";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import SignReportList from "./components/SignatureReportList.vue";
import DataType from "@/constants/data-type.js";
import SimpleFilter from "@/components/common/display/item-actions/components/SimpleFilter.vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import { exportData } from "@/helpers/export-excel-data";
import FileSheet from "../repository-browse-and-trash/components/FileSheet.vue";
import formatBytes from "@/helpers/format-bytes.js";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import SaveSheet from "@/components/common/display/item-actions/components/SaveSheet.vue";
import FilterListType from "@/components/common/display/item-actions/components/FilterListType.vue";
import ConfirmDelete from "@/components/common/display/item-actions/components/DeleteSaveItems.vue";
import Breadcrumbs from "@/views/repositories/views/repository-browse-and-trash/components/Breadcrumbs.vue";

export default {
  name: "RepositoriesSignatureReports",

  components: {
    Portal,
    ItemActions,
    SignReportList,
    SimpleFilter,
    StateWrapper,
    Pagination,
    FileSheet,
    AppLayout,
    ViewWrapper,
    ProcessHistory,
    SaveSheet,
    FilterListType,
    ConfirmDelete,
    Breadcrumbs,
  },

  data() {
    return {
      mode: "REPORT",
      activeView: "TABLE",
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "folders",
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          label: "reports",
          route: "repositories-reports-overview",
        },
        {
          id: this.$nano.id(),
          label: "",
          route: "",
        },
      ],
      tabs: [
        // {
        //   id: this.$nano.id(),
        //   label: "overview",
        //   icon: "mdi-view-dashboard-outline",
        //   route: "repositories-overview",
        //   access: false,
        //   accessLabel: "Overview",
        // },
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "repositories-browse",
          access: true,
          accessLabel: "Manage Folders",
        },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "repositories-trash",
          access: true,
          accessLabel: "Manage Folders",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "deleted files",
        //   icon: "eva-trash-2-outline",
        //   route: "file-trash",
        //   access: false,
        //   accessLabel: "Manage Folders",
        // },
        {
          id: this.$nano.id(),
          label: "reports",
          icon: "eva-trash-2-outline",
          route: "repositories-signature-report",
          access: false,
          accessLabel: "Audit Report",
        },
      ],
      tab: "repositories-signature-report",
      columns: [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "signType",
          label: "Sign Type",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          isVisible: true,
          options: this.fileStatus,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
          onClick: this.showHistory,
        },
        {
          id: this.$nano.id(),
          name: "requestedBy",
          label: "Requested By",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "currentUser",
          label: "Current User",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "requestedAt",
          label: "Requested At",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        // {
        //   id: this.$nano.id(),
        //   name: "Stage",
        //   label: "Stage",
        //   isVisible: true,
        //   isSortable: true,
        //   isGroupable: true,
        //   dataType: DataType.DATE,
        // },
        {
          id: this.$nano.id(),
          name: "version",
          label: "version",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileSize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ],
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      hideFileSheet: false,
      userList: [],
      workspaceId: 0,
      workspaceList: [],
      itemId: 0,
      repositoryId: 0,
      repositoriesList: [],
      SignReportList: [],
      repositorySignReportList: [],
      repositorySignReportListData: [],
      options: {},
      selectedFile: {},
      isFileSheetVisible: false,
      loadingBarContent: false,
      fileStatus: [
        {
          id: this.$nano.id(),
          label: "Archived",
          value: "Archived",
        },
        {
          id: this.$nano.id(),
          label: "Deleted",
          value: "Deleted",
        },
      ],
      signType: [
        {
          id: this.$nano.id(),
          label: "e-sign",
          value: "esign",
        },
        {
          id: this.$nano.id(),
          label: "Digital Sign",
          value: "digitalSign",
        },
      ],
      exportDataList: false,
      historyProcessId: 0,
      selectedProcess: {},
      filterId: "",
      WfilterList: [],
      issavesheet: false,
      showFilterList: false,
      savedList: [],
      columnss: [],
      savedListData: [],
      isDeleteConfirmationVisible: false,
      deleteData: {},
      success: false,
      editData: [],
      breadcrumb: [],
    };
  },

  computed: {
    _columns() {
      let columns = [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "FileName",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "requestedAt",
          label: "Requested At",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "requestedBy",
          label: "Requested By",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "currentUser",
          label: "Current User",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          options: this.fileStatus,
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "signType",
          label: "Sign Type",
          options: this.signType,
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
      ];
      columns[2].options = this.userList;

      return columns;
    },

    _tabs() {
      if (this.$store.state.profileMenus.length) {
        this.tabs.forEach((tab) => {
          if (!tab.access) {
            tab.access = this.$store.state.profileMenus.find(
              (row) => row.folders
            )?.Menu[tab.accessLabel];
          }
        });
      }
      return this.tabs.filter((tab) => tab.access);
    },

    items() {
      return this.repositorySignReportList.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon: item.status
            ? "eva-checkmark-circle-2-outline"
            : "eva-clock-outline",
          color: item.status ? "green" : "orange",
          iconTooltip: item.status ? "Completed" : "Processing",
          itemId: item.itemId,
          link: true,
          fileName: item.fileName,
          fileSize: item.fileSize ? formatBytes(item.fileSize) : "",
          fileStatus: item.status,
          signType: item.signType,
          requestedAt: this.$day.format(item.requestedAt),
          requestedBy: item.requestedByEmail,
          version: item.version,
          currentUser: item.currentUser,
          Stage: item.stageName,
          pId: item.processId,
          wId: item.workflowId,
        })),
      }));
    },

    savedItems() {
      return this.savedListData.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((item) => ({
          icon: "mdi-text-box",
          id: item.id,
          itemId: item.itemId,
          link: true,
          name: item.name,
          description: item.description,
          createdBy: item.createdByEmail,
          assignTo: item.assignTo.length,
          createdon: this.$day.format(item.createdAt),
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },

    workspaceId: {
      deep: true,
      immediate: true,
      handler() {
        this.clearData();
        if (this.workspaceId) {
          this.getRepositories();
        }
        if (this.workspaceId && this.repositoryId) {
          this.getSignReport();
        }
      },
    },

    repositoryId: {
      deep: true,
      immediate: true,
      async handler() {
        this.repositorySignReportList = [];
        if (this.repositoryId) {
          this.getSignReport();
        }
      },
    },

    itemsPerPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.itemsPerPage) {
          this.getSignReport();
        } else if (this.savedListData && this.itemsPerPage) {
          this.getFilterData();
        }
      },
    },

    currentPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId && this.repositoryId && this.currentPage) {
          this.getSignReport();
        } else if (this.savedListData && this.itemsPerPage) {
          this.getFilterData();
        }
      },
    },

    filterId: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.filterId === "") {
          this.repositorySignReportList = [];
          this.workspaceId = 0;
          this.repositoryId = 0;
          this.filterBy = [];
        } else if (this.filterId === "-1") {
          this.breadcrumb = [];
          this.breadcrumb.push({
            id: 0,
            label: "Save Search",
            entity: "this.selectedEntity",
            type: "REPOSITORY ACTIVITY",
          });
          this.repositoryOcrReportList = [];
          this.showFilterList = true;
          this.getFilterData();
        } else if (this.filterId) {
          this.columnss = [];
          this.showFilterList = false;
          this.filterList();
          this.selectedIds = this.filterId;
        } else if (!this.filterId) {
          this.showFilterList = false;
          this.repositoryReportList = [];
        }
      },
    },
    success: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.success) {
          await this.getFilterList();
          if (this.filterId === "-1") {
            this.getFilterData();
          }
        }
      },
    },
  },

  created() {
    this.getWorkspaces();
    this.getUsers();
    this.getFilterList();
  },

  methods: {
    refreshData() {
      if (this.workspaceId && this.repositoryId) {
        this.getSignReport();
      } else if (this.workspaceId) {
        this.getRepositories();
      }
    },

    searchRow(search) {
      if (search) {
        this.repositorySignReportList = this.repositorySignReportListData.map(
          ({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = this._columns.findIndex((column) => {
                  return column.name === cell;
                });
                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),
          })
        );
      } else {
        this.repositorySignReportList = this.repositorySignReportListData;
      }
    },

    async export_Data() {
      //this.$store.commit("showLoadingBarPage");
      this.loadingBarContent = true;
      const { error, payload } = await report.getSignReport(this.repositoryId, {
        filterBy: this.filterBy,
        itemsPerPage: 500,
        currentPage: 1,
      });
      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      let tableData = [];
      payload.data.forEach((row) => {
        row.value.forEach((item) => {
          tableData.push({
            "File Name": item.fileName,
            Status: item.completedAt || "Processing",
            "Requested By": item.requestedByEmail,
            "Current User": item.currentUser,
            "Requested At": this.$day.format(item.requestedAt),
            Size: item.fileSize ? formatBytes(item.fileSize) : "",
            "Sign Type": item.signType,
            version: item.version,
            // "Stage":item.stageName,
          });
        });
      });

      if (tableData.length) {
        let selectedName = this.repositoriesList.find(
          (repository) => repository.value === this.repositoryId
        );
        exportData(tableData, selectedName.label + "-Signature Report");
      }
    },

    openFile(rowId) {
      let selectedList = this.repositorySignReportList[0].value.find(
        (file) => file.rNo === rowId
      );
      this.selectedFile = {
        id: selectedList.itemId,
        name: selectedList.fileName,
        size: selectedList.fileSize,
        repositoryId: this.repositoryId,
        createdAt: selectedList.requestedAt,
        createdBy: selectedList.requestedByEmail,
      };
      this.isFileSheetVisible = true;
    },

    /* api Functions */

    async getWorkspaces() {
      //this.$store.commit("showLoadingBarPage");
      this.loadingBarContent = true;

      const { error, payload } = await workspace.getWorkspaceList();

      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.workspaceList = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.id,
      }));
    },

    async getRepositories() {
      if (this.workspaceId) {
        this.repositoriesList = [];
        // this.$store.commit("showLoadingBarPage");
        this.loadingBarContent = true;

        const { error, payload } = await repository.getRepositoryList(
          "workspaceId",
          this.workspaceId
        );

        this.loadingBarContent = false;
        // this.$store.commit("hideLoadingBarPage");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (!payload.length) {
          return;
        }
        this.repositoriesList = payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.id,
        }));
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value,
            value: user.value,
          });
        });
      }
    },

    async getSignReport() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBarPage");
      this.repositorySignReportList = [];
      this.repositorySignReportListData = [];
      this.list = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = JSON.stringify([row.arrayValue]);
            }
          });
        }
      });
      const { error, payload } = await report.getSignReport(this.repositoryId, {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      // this.$store.commit("hideLoadingBarPage");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        this.repositorySignReportList = [];
        this.repositorySignReportListData = [];
      } else {
        console.log(payload.data);
        this.repositorySignReportList = payload.data;
        this.repositorySignReportListData = payload.data;
        if (this.repositorySignReportList.length) {
          this.exportDataList = false;
          this.exportDataList = true;
        }
      }
    },

    async openFilter(rowId) {
      this.showFilterList = false;
      this.filterId = "0";
      this.listedData.map((item) => {
        if (item.id === rowId) {
          // let search = item.filterJson;

          // const filterItem = search.slice(1, -1);
          const parsedArray = JSON.parse(item.filterJson);
          let filters = [];
          if (parsedArray[0].filters) {
            filters = JSON.parse(parsedArray[0].filters);
          }
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            id: item.itemId,
            name: item.name,

            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      this.repositoryId = this.filterListData.rId;
      await this.getRepository();
      this.filterFrom = this.filterListData.filterFrom;
      this.repositoryId = this.filterListData.rId;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      this.getSignReport();
    },

    async getData(fromDate, toDate) {
      const filterBy = [
        {
          id: this.$nano.id(),
          filters: [
            {
              id: this.$nano.id(),
              criteria: "requestedAt",
              condition: "IS_EQUALS_TO",
              value: fromDate,
              valueTo: toDate,
              dataType: "DataType.DATE",
            },
          ],
          groupCondition: "",
        },
      ];

      this.$store.commit("showLoadingBarPage");
      this.repositorySignReportList = [];
      this.repositorySignReportListData = [];
      this.list = [];
      const { error, payload } = await report.getSignReport(
        this.repositoryId,

        {
          sortBy: this.sortBy,
          filterBy: filterBy,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        }
      );
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.data.length) {
        payload.data.forEach((row) => {
          row.value.forEach((process) => {
            this.SignReportList.push({
              ...process,
            });
          });
        });
      }

      this.totalItems = payload.meta?.totalItems || 0;

      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.repositorySignReportList = [];
        this.repositorySignReportListData = [];
      } else {
        this.repositorySignReportList = payload.data;
        this.repositorySignReportListData = payload.data;
      }
    },

    clearData() {
      this.exportDataList = false;
      this.repositoryId = 0;
      this.repositorySignReportList = [];
    },
    showHistory(item) {
      this.historyProcessId = item.pId;
      this.selectedProcess = {
        requestNo: item.fileName,
        id: item.wId,
      };
    },

    async getFilterData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBarPage");
      this.savedList = [];
      this.savedListData = [];
      this.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              row.value = row.arrayValue;
            }
          });
        }
      });
      // const { error, payload } = await report.getRepositoryData(
      //   this.repositoryId,
      //   {
      //     itemId: this.itemId,
      //     filterBy: this.filterBy,
      //     itemsPerPage: this.itemsPerPage,
      //     currentPage: this.currentPage,
      //   }
      // );
      // this.$store.commit("hideLoadingBarPage");
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "repositorySignReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.columnss = [
        {
          id: this.$nano.id(),
          name: "id",
          label: "Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "itemId",
          label: "Item Id",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdon",
          label: "Created On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "assignTo",
          label: "Assign To",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ];

      this.loadingBarContent = false;
      if (payload.data.length) {
        payload.data.forEach((row) => {
          row.value.forEach((process) => {
            this.SignReportList.push({
              ...process,
            });
          });
        });
      }

      this.totalItems = payload.meta?.totalItems || 0;
      if (this.totalItems === 0) {
        //this.$alert.info("No records found");
        this.savedList = [];
        this.savedListData = [];
      } else {
        this.savedList = payload.data;
        this.savedListData = payload.data;
        if (this.savedList.length) {
          this.exportDataList = true;
        }
      }
      console.log(this.savedListData, "this.savedListData");
    },

    async getFilterList() {
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "repositorySignReport",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }

      let data = payload.data[0].value;
      this.listedData = data;

      let List = data.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        value: filter.name,
      }));

      const removeObserver = (obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
          if (key !== "__ob__") {
            newObj[key] = obj[key];
          }
        });
        return newObj;
      };
      const arrayOfObjects = List.map((obj) => removeObserver(obj));
      const viewAll = { id: this.$nano.id(), label: "Viewall", value: "-1" };

      this.WfilterList = [
        ...(arrayOfObjects.length ? [viewAll] : []),
        ...arrayOfObjects,
      ];
    },

    async filterList() {
      this.listedData.map((item) => {
        if (item.name === this.filterId) {
          // let search = item.filterJson;
          // const filterItem = search.slice(1, -1);
          const parsedArray = JSON.parse(item.filterJson);
          let filters = [];
          if (parsedArray[0].filters) {
            filters = JSON.parse(parsedArray[0].filters);
          }
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            id: item.itemId,
            name: item.name,

            rId: parsedArray[0].uId,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      this.repositoryId = this.filterListData.rId;
      if (this.filterId !== "0") {
        await this.getRepository();
      }
      this.filterFrom = this.filterListData.filterFrom;
      this.repositoryId = this.filterListData.rId;
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      this.getSignReport();
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.repositoryDetails = payload;
      this.workspaceId = this.repositoryDetails.workspace.id;
      if (payload.options) {
        this.options = payload.options;
      }

      this.repositoryField = this.repositoryDetails.fields;
      this.columns = [
        {
          id: this.$nano.id(),
          name: "fileName",
          label: "File Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileStatus",
          label: "Status",
          isVisible: true,
          options: this.fileStatus,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "ocrType",
          label: "Type",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "processedBy",
          label: "Processed By",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          options: this.userList,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedOn",
          label: "Processed On",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "totalPage",
          label: "Total Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "processedPages",
          label: "Processed Pages",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fileSize",
          label: "Size",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
      ];
    },
    deleteItem(items) {
      this.savedListData[0].value.map((item) => {
        if (item.id === items) {
          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
    edit(items) {
      this.editData = [];
      this.savedListData[0].value.map((item) => {
        if (item.id === items) {
          this.issavesheet = true;
          this.editData = [
            {
              id: item.id,
              name: item.name,
              description: item.description,
              filterJson: item.filterJson,
              filterFrom: item.filterFrom,
              createdAt: item.createdAt,
              modifiedAt: item.modifiedAt,
              createdBy: item.createdBy,
              modifiedBy: item.modifiedBy,
              dashboard: item.pinDashboard,
              assignTo: item.assignTo,
            },
          ];
        }
      });
    },
    saveSheet() {
      this.editData = [];
      this.issavesheet = true;
    },
    homeRepository() {
      this.filterId = "0";
      this.getFilterList();
      this.breadcrumb = [];
    },
    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#repository-sign-reports {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 300px);
  }

  .backGround {
    border: 2px;
    border-radius: 10px;
    background-color: #87dcf626;
    margin-bottom: 8px;
  }
}
</style>

<style lang="scss">
#select-field.fieldList {
  .select {
    min-height: 0px !important;
    color: $secondary !important;
    width: 140px !important;
    overflow: hidden !important;
  }
}

#text-field.textField {
  input {
    min-height: 35px;
    width: 140px;
  }
}

.loading-content {
  background-color: var(--body-bg-color) !important;
  position: absolute;
  z-index: 9999;
}
</style>
